<template>
  <div class="contract-create">
    <header>
      <div class="container d-flex justify-content-between align-items-center">
        <div>
          <h1>
            {{ buyer.surname }}
            {{ buyer.name }}
          </h1>
          <!--          <p>Вы не верефецированны, оформив договор вы пройдете регистрацию чтоб сделка состоялась</p>-->
        </div>
        <!--        <span>{{ buyer.status === 4 ? 'Верефицирован' : '' }}</span>-->
      </div>
    </header>

    <section class="container products">
      <h1 class="font-size-24 font-weight-700 mt-24 mb-16">Товары</h1>
      <products-table
        class="mb-32"
        :products="[product]"
        :remainder="remainder"
      />

      <h1 class="font-size-24 font-weight-700 mb-24">Расчет стоимости</h1>
      <label for="period">Срок рассрочки</label>
      <div class="row mt-4">
        <div class="col-md-4">
          <select
            id="period"
            class="form-control"
            v-model="period"
            @change="calculate"
          >
            <option value="0">Выберите срок</option>
            <option value="6">6</option>
            <option value="9">9</option>
            <option value="12">12</option>
          </select>
        </div>

        <div class="col-md-4 d-flex align-items-center">
          <info-card
            class="payment-monthly"
            title="Ежемесячный платеж:"
            :sub-title="this.$numberFormat(paymentMonthly).toString()"
          />
        </div>

        <div class="col-md-4 d-flex align-items-center">
          <info-card
            class="total-credit"
            title="Итого с учетом рассрочки:" sub-title="null">
            <template #subTitle>
              <span class='text-main'>{{ $numberFormat(totalCredit) }}</span>
            </template>
          </info-card>
        </div>
      </div>
    </section>

    <div v-if="!smsSent" class="row d-flex justify-content-center mt-56">
      <div class="col-md-4">
        <main-button @click="sendSMS">Создать договор</main-button>
      </div>
    </div>
    <div v-else>
      <div class="row d-flex justify-content-center mt-56">
        <div class="col-md-4">
          <label for="sms">SMS подтверждение</label>

          <input
            v-model="smsCode"
            v-mask="`####`"
            id="sms"
            type="text"
            class="form-control mt-4"
            placeholder="Введите код"
          >
        </div>
        <div class="col-md-4">
          <main-button class="mt-23" @click="createContract">Оформить рассрочку</main-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from '../config';
import localize from '../filters/localize.filter';

import InstallmentService from '../services/installment.service';
import ProductService from '@/services/product.service';

import InfoCard from '../components/cards/InfoCard';
import MainButton from '../components/buttons/MainButton';
import ProductsTable from '../components/tables/ProductsTable';

export default {
  name: 'ContractCreatePage',
  props: ['productId'],
  components: {
    ProductsTable,
    InfoCard,
    MainButton,
  },
  data: () => ({
    product: null,
    period: 12,
    total: 0,
    totalCredit: 0,
    paymentMonthly: 0,
    smsSent: false,
    smsCode: null,
    contractId: null,
  }),
  computed: {
    buyer() {
      return this.$store.getters['buyers/userInfo'];
    },
    remainder() {
      return this.product.amount * this.product.price;
    },
  },
  methods: {
    localize,
    calculate(e) {
      const month = parseInt(e.target.value);

      this.totalCredit = InstallmentService.calculate(this.product.price, month);
      this.paymentMonthly = InstallmentService.perMonth(this.product.price, month);
    },
    async sendSMS() {
      const { data: response } = await this.$axios.post('buyers/credit/add', {
        products: this.formattedProducts,
        limit: this.period,
        buyer_phone: this.buyer.phone,
      }, {
        headers: {
          Authorization: `Bearer ${config.magoToken}`,
        },
      });

      if (response.status === 1) {
        this.smsSent = true;

        console.log('sms has been sent!');
        this.contractId = response.paymart_client.contract_id;
      } else {
        this.$toastError(response.message);
      }
    },
    async createContract() {
      if (this.smsCode !== null) {
        const { data: response } = await this.$axios.post('buyers/check-user-sms', {
          contract_id: this.contractId,
          phone: this.buyer.phone,
          code: this.smsCode,
        });

        if (response.result.status === 1) {
          console.log('contract created!');
          this.$toastSuccess(response.result.message);
          await this.$store.commit('REMOVE_PRODUCT_FOR_BUY')
          await this.$router.push({ name: 'profile.contracts' });
        }
      } else {
        this.$toastError('Введите смс код!');
      }
    },
    async getBuyerDetail() {
      const { data: response } = await this.$axios.get('buyers/detail');
      await this.$store.dispatch('buyers/fetchUserInfo', response.data);
    },
  },
  created() {
    this.getBuyerDetail();

    this.product = ProductService.getOne(this.productId);

    this.totalCredit = InstallmentService.calculate(this.product.price, this.period);
    this.paymentMonthly = InstallmentService.perMonth(this.product.price, this.period);
  },
};
</script>

<style lang="scss" scoped>
  .contract-create {

    @media (max-width: $mobile) {
      padding-bottom: 100px;
    }

    header {
      padding: 56px 0;
      background: $grey url('../assets/icons/Vector.svg') no-repeat calc(50% - 120px) 37px;

      h1 {
        font-weight: 900;
        font-size: 40px;
        line-height: 40px;
        color: $black;
      }
    }

    section.products {
      select {
        @media (max-width: $mobile) {
          margin-bottom: 18px;
        }
      }

      .payment-monthly {
        @media (max-width: $mobile) {
          margin-bottom: 18px;
        }
      }
    }
  }
</style>
